
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/index.vue?macro=true";
import { default as indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta } from "/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta?.name ?? "index",
    path: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta?.path ?? "/",
    props: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta?.props ?? false,
    meta: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta || {},
    alias: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta?.alias || [],
    redirect: indexBndHh5luHkpRLMBiBU_452zYAf1sbgRR4tieygYUY2u54Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/index.vue")
  },
  {
    name: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta?.name ?? "lang",
    path: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta?.path ?? "/:lang?",
    props: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta?.props ?? false,
    meta: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta || {},
    alias: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta?.alias || [],
    redirect: indexgC9FhghtN_aE8_UNCTzchNpecBP6lWi7HmIMkx9G0u8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta?.name ?? "lang-voucher-start",
    path: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta?.path ?? "/:lang?/voucher/start",
    props: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta?.props ?? false,
    meta: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta || {},
    alias: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta?.alias || [],
    redirect: start98ZGAwTMhbLZIEjY0SUKxQ1sJDg_ImCJVivwPi2A0coMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta?.name ?? "lang-purchase-start",
    path: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta?.path ?? "/:lang?/purchase/start",
    props: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta?.props ?? false,
    meta: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta || {},
    alias: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta?.alias || [],
    redirect: startvw0i2MT0E5loc1shpRDj5Znou9LYZsQp9smoO5qYkXgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta?.props ?? false,
    meta: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta || {},
    alias: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta?.alias || [],
    redirect: checkoutcP4_45L2Gz_kYyfZPjaXr_VJD29kM1bS1ccDAE6X640RoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta?.props ?? false,
    meta: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta || {},
    alias: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta?.alias || [],
    redirect: koUdNSeu7BkBp0cbIZSpdVbVXEPe0cK3dUldC7lNyReiEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta?.props ?? false,
    meta: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta || {},
    alias: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta?.alias || [],
    redirect: confirmationOXQp7H5rpIuiEYCUP3pkc4i0QUqaj9bq_SYE44qvp9IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta?.props ?? false,
    meta: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta || {},
    alias: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta?.alias || [],
    redirect: confirmation_X_UuwPIT3qwtDVa_45MuN2pM_H3NWO30iIfioCQJQTyIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta?.props ?? false,
    meta: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta || {},
    alias: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta?.alias || [],
    redirect: buyer7bRPzV4SpYS_45vE9E2mI2ClUDWC8bwhRTHi8jGtE_eCIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta?.props ?? false,
    meta: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta || {},
    alias: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta?.alias || [],
    redirect: buyeryQNnfsezFAQhPS5QpjgrmanLYuwpUhkzXT7Ai_45e3hPoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta?.props ?? false,
    meta: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta || {},
    alias: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta?.alias || [],
    redirect: paymentcYrD3VxkZEmvwe3dI7xGL7oxm__455hc7b2hJ9ZcdRdlQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta?.props ?? false,
    meta: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta || {},
    alias: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta?.alias || [],
    redirect: challengejvFaC_T7W_45LWJDujhaB6xrLtQq_45iBR91I5bIxqk8khcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta?.props ?? false,
    meta: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta || {},
    alias: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta?.alias || [],
    redirect: paymentkRxnafO_45OgtcyQdGNYgDOvJdNluHaqojefpVkq_w2c0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/almeria/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/almeria/alhama-de-almeria/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/almeria/almeria/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/almeria/roquetas-de-mar/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/almeria/vera/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/arenys-de-mar/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/barcelona/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/berga/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/caldes-de-montbui/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/calella/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/la-garriga/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/masquefa/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/mataro/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/sant-joan-despi/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/seva/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/sitges/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/barcelona/vallromanes/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/barbate/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/cadiz/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/chiclana-de-la-frontera/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/grazalema/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/novo-sancti-petri/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 27",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/rota/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 28",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/tarifa/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 29",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/vejer-de-la-frontera/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 30",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/cadiz/zahara/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 31",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 32",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/el-perello/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 33",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/montbrio-del-camp/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 34",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/salou/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 35",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/torroja-del-priorat/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 36",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tarragona/vila-seca/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 37",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 38",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/adeje/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 39",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/arona/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 40",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/guia-de-isora/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 41",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/playa-de-las-americas/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 42",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/puerto-de-la-cruz/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 43",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/san-cristobal-de-la-laguna/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 44",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/san-miguel-de-abona/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 45",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/santa-cruz-de-tenerife/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.name ?? "Spa by location landing: 46",
    path: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.path ?? "/spa-balneario/tenerife/santa-ursula/",
    props: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.props ?? false,
    meta: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta || {},
    alias: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.alias || [],
    redirect: SpaList_46pagei5rOX3GyTlngYc7xMxqFvIU5qzMFbSyhyhq9BXh8GsgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-cortijo/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/shama-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/inner-flow/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 42",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/aquatonic/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 43",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 44",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 45",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 46",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 47",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 48",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 49",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 50",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 51",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 52",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 53",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 54",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 55",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 56",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/sens-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 57",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 58",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 59",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 60",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 61",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 62",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 63",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 64",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 65",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 66",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 67",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 68",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 69",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 70",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 71",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 72",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 73",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 74",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 75",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 76",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 77",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 78",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 79",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/hammam-vejer/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 80",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 81",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 82",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 83",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 84",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.name ?? "Spa landing: 85",
    path: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/",
    props: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.props ?? false,
    meta: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta || {},
    alias: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.alias || [],
    redirect: SpaDetail_46pageUBXYRZxuGdMeiMczHEERUC4wnJC8NQZy00sifsCDBS4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-estancia-1-noche-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/momento-spa-masaje-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/spa-masaje-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-merienda-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-cena-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-spa-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-ritual-115-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-aromatico-45-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-hidraforce-source-marine-55min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/serenity-love/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/family-day-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-massage-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/gastrospa-lunes-a-viernes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-individual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy-55-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sprunch-con-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/after-work-30-min-masaje-con-circuito-termal-lunes-a-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/senses-night/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/manicura-y-pedicura-express-con-acceso-a-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/iberostar-vegan-facial-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/brunch-sin-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-hydraluronic/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-provenza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-menores-4-a-17anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-placido-sueno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reina-de-egipto-circuito/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-45/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-para-dos-masaje-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-armonia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-consecutivo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/masaje-karite-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet-masaje-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lomi-lomi-nui-70-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/foot-reflexology/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/oriental-55-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/earth-experience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/andalucia-playa-signature-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/body-sculpt-anti-cell/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-breakfast/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/circuito-hydrowellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/circuito-spa-privado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-dinner-market-experience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/stone-therapy/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/escapada-parejas-estancia-masaje-spa-piscina-fitness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/terssia-luxury-experience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/circuito-de-hidroterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/diamond-package-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/aftersun-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-lomi-lomi-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/ritual-perfect-body-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-holistico-relajante-50-min-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/radiance-ritual-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/romantic-package-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-tejido-profundo-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/esfoliacion-corporal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/purify-ritual-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-holistico-relajante-30-min-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/gold-package-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/reflexologia-podal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-completo-55-min-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-vip-en-sala-privada-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-spa-10-sesiones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje-doble-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/anthelia-signature-massage-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/oriental-55-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especifico-localizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-masaje-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/spa-en-familia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sprunch-spa-brunch/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/momento-spa-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-care-para-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-eternal-love-para-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-sensualidad/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-alegria/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/kids-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-2-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-nino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/gourmet-love-para-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-gourmet-para-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa-acceso-gimnasio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-relajante-aromaterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/peeling-exfoliante-corporal-piel-de-seda-40mins/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/pack-bienestar-para-dos-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-completo-muscular-profundo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-terapeutico-espalda-cuello-y-hombro/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/like-a-diamond/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/endeless-summer/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/circuito-spa-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/beach-rehab/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/southside-story/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/fall-in-love/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/la-residencia-crazy-in-love/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/time-for-you/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/drenaje-linfatico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/piel-de-seda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/reflexologia-podal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/spa-relax-2-horas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/circuito-thalassa-1-hora/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/ritual-vincci-sun/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-40-min-y-spa-2-horas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-20-min-y-spa-2-horas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-celta-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-abhyanga/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/glow-flow-spa-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/deep-tissue-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/aromatherapy-back-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/rejuvenate/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/balmoral-signature-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/time-ritual-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/antistress-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relajacion-masaje-y-desayuno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/glow-recovery-facial-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-signature-facial-by-scens-40-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-65-minutos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-silver-edition/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desconecta-para-conectar/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/cuidado-maternal-65min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-de-25-minutos-infantil/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/escapada-relax-foodie-spa-menu-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun-pareja-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-25-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-55-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/de-este-a-oeste/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-anantara/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/tiempo-para-dos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/oasis-de-serenidad/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-tradicional-tailandes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-muscular-profundo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/dulce-espera/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/facial-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/experiencia-anantara-con-masaje-y-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/viaje-a-traves-de-los-sentidos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa-residente-canario/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-familiar-mascarilla-facial-solidaria/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/silhoutte-perfection-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hot-stone-bliss-90/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/deep-tissue-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/exfoliation-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/time-ritual-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/antiestres-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/aromatherapy-back-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/sir-victor-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/eternal-youth/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/exfoliation/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/deep-tissue-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/slow-glow-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/cityscape-spa-masaje-y-gastronomia-en-el-rooftop/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-y-circuito-spa-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-serena-spa-luxury-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/white-light/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-couple-cocoon-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-spa-privado-90/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hot-stone-bliss/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-acceso-general-circuito-de-aguas-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/digital-wellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/melia-exclusive-signature-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/time-ritual-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/deep-tissue-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/exfoliation/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-especializado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-ayurveda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-anti-dolor-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-lomi-lomi-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-en-pareja-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-masaje-aromaterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/reflexologia-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-express/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-y-moet-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-muscular-profundo-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-piernas-cansadas-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-craneal-de-la-india/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mediterranean-flavours-90/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/signature-journey/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sweet-orange-body-scrub/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rejuvenate-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-con-opcion-masaje-y-cena-para-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/ritual-facial-y-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-ritual-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/hidratante-hialuronico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/firmeza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/duo-reafirmante-facial-y-corporal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-relajante-aromatico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-de-aguas-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-90/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-spa-privado-para-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/classic-spa-circuito-de-aguas-90/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-en-familia-para-3-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-muscular-profundo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-localizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-canario/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-exclusive-spa-privado-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/2x1-spazio-scape-circuito-termal-60min-lunes-a-viernes-11h-14h/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal-masaje-aromaterapia-40min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/peeling-con-hidratacion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/age-defense-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/ocean-miracle-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sensations-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/higiene-activa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/cacao-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/infinity-60min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/secretos-del-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/deep-tissue-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/bambu-detox-80min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-lovers-circuito-termal-1h-masaje-cacao-tamakuru-40min-para-dos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-zen-circuito-termal-60min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-celebrate-spa-privado-grupos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/holistico-del-tibet-90min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-luxury-circuito-termal-120min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/ritual-al-andalus/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/limpieza-facial-profunda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-oliveres-masaje-de-55-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-spa-oliveres-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-spa-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/circuito-ohtels/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/circuito-ohtels-masaje-15-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/dia-en-inner-flow/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/bienvenido-a-tu-primera-experiencia-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-vez-primer-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-experiencia-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-buffet-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-radiance-vitamina-c/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-pureza-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-hidraluronic/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-buffet-menores-4-anos-cumplidos-a-menores-de-18/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-menores-4-anos-cumplidos-a-menores-de-18/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/2-circuitos-spa-premium/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-de-cereza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-placido-sueno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-oro-y-aceites-esenciales-relajantes-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-anticelulitis-masaje-envoltura-anticelulitica/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-terapeutico-deportivo-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-colageno-marino-ojos-incluidos-natura-bisse/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-lifting-alisa-rellena-tensa-silicium-marino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-cocoon-facial-anti-polucion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-hyaluronico-marino-anti-edad-activador-de-juventud/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-rosa-terapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-espalda-cuello-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/bamboo-scrub-peeling-hidratacion-corporal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/vedic-massage-60-min-thai-room/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/futura-mama-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-hammam/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-de-aloe-vera-y-hojas-de-platanera/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/detox-oxigenante-60min-biologique-recherche/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-en-pareja-60-minutos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-relajante-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-day/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-residente-canario/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-brunch-by-bahia-del-duque/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-energizante-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja-masaje-25-min-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja-masaje-50-min-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-aloe-vera/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-en-pareja-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-descontracturante-relajante-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-en-parejas-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-masaje-relajante-cena-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-masaje-relajante-cena-buffet-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-de-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/desayuno-buffet-circuito-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/desayuno-buffet-circuito-spa-masaje-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-cena-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-chocolate-juvenil/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-con-piedras-volcanicas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/acceso-spa-120-min-masaje-relajante-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/acceso-spa-60-min-masaje-relajante-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/spa-privado-60-min-cava-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/acceso-spa-60-min-cata-de-vinos-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/acceso-spa-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-wellness-mas-den-bruno/acceso-spa-120-min-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adultos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-kobido-emperatriz/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-flash/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-shi-zen-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-ninos-4-15-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min-doble/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/padres-e-hijos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/luz-del-mediterraneo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranea/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-junior/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/limpieza-hidratacion-con-acido-hialuronico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia-radiofrecuencia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-para-la-novia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche-para-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-de-60-minutos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cavitacion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/envoltura-canaria/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip-para-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-persona-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-30-minutos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-respira-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-romantica-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/presoterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personasresidente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personas-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/domingo-familiar-domingos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-0-5-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-6-12-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/viernes-juvenil/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-vip/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-13-17-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cumpleanos-con-picoteo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aqua-baby/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-no-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/experiencia-wellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-placido-sueno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/apacible-paraiso/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-menores-4-a-17-99-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/experiencia-sun-repair/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-clasico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-2-dias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-sales/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-algas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-de-la-polinesia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-esencias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-indonesio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-hidratante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lomi-lomi-80m/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aromatico-80m/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/thai-50m/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/piedras-volcanicas-50m/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-oriental/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-canario/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-dragon-de-oro/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-estudiante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-senior/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-cocktail/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-masaje-bienestar-duo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-masaje-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-masaje-relax-duo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-nino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-en-familia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/experiencia-romantica-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/ritual-cleopatra-bano-aromatico-masaje-relajante-y-masaje-craneal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-2-adultos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/shiatsu/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/tuina/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/hindu/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/masaje-purificante-con-minerales/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/masaje-aromatico-al-aceite-de-lavanda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/reiki/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/circuito-termal-masaje-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/hidratante-al-aceite-de-argan/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/stone/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/relajante-a-la-canela/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/circuito-termal-masaje-aromatico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/ayurvedico-con-aceite-de-sesamo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/californiano/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/duo-delicia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/balines/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/duo-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/oasis-de-la-serenidad/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pura-elegancia-by-alqvimia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-afterwork-masaje-coctel/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-facial-kobido/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-morning-up-desayuno-zona-de-hidroterapia-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/friendly-majestic-duo-moments/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-quierete/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia-facial-antiedad-cena/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/diamond-energy-by-natura-bisse/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/oriental-touch-signature-treatment/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/c-vitamin-coctel-by-natura-bisse/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pack-majestic-morning-up/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/toque-de-calma-by-alqvimia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/termal-experience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-relax-termal-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-bienestar-circuito/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-radiance-c/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-provenza-circuito/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-srns/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exfoliantes-corporales/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-menores-de-4-a-17-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/envolturas-corporales/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/2-desayunos-piscina-cubierta-bano-turco-sauna-masaje-en-pareja-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/dia-especial-en-pareja-masaje-50-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-piscina-cubierta-sauna-bano-turco-masaje-de-45min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-masaje-en-pareja-de-25min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/2-desayunos-piscina-climatizada-sauna-bano-turco-masaje-50-minutos-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-masaje-50-min-en-pareja-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/desayuno-masaje-45-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/dia-especial-en-pareja-masaje-25-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/desayuno-masaje-25-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-piscina-cubierta-sauna-bano-turco-masaje-de-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-piedras-volcanicas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-45/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-en-pareja-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-hawaiano/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-en-pareja-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/hidratacion-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/piscina-cubierta/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-juvenil-aromatico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two-luxury/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/smooth-operator/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/wrap-remix/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-desayuno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/inner-peace/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/synchronicity-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/deep-tissue-50/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/day-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-cena-buffet/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/acceso-circuito-spa-cliente-residente/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/its-your-time/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/jelly-gum-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/face-the-music/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-descarga-45min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/hidradermis-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60-min-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-45-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-y-templarium/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-ashanti-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 669",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-en-pareja-masaje-de-30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 670",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/chocolaterapia-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 671",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-brunch/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 672",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 673",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-romantico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 674",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 675",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/circuito-termal-especial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 676",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/circuito-termal-especial-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 677",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 678",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/circuito-termal-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 679",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-120-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 680",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-90-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 681",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 682",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/serenity-full-body-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 683",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/sport-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 684",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/detox-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 685",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/deep-tissue-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 686",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 687",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/oxigenoterapia-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 688",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-c-plus-energy/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 689",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-pies-de-seda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 690",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-timexpert/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 691",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/exfoliante-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 692",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/quiromasaje-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 693",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-eyelift/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 694",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-kobido/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 695",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/nature-aloe/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 696",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-pediluxury/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 697",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/zen-para-dos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 698",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-piernas-cansadas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 699",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/belleza-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 700",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/spa-masaje-para-dos-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 701",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-manos-de-seda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 702",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-dulce-espera/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 703",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-bambu/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 704",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-aloexperience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 705",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/reflexologia-podal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 706",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-anticelulitico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 707",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 708",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-locura-praline/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 709",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-craneofacial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 710",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/spa-masaje-para-dos-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 711",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-polinesia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 712",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/quiromasaje-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 713",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-abhyanga/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 714",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/circuito-spa-adulto/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 715",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/relax-clean/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 716",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-oxigeno/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 717",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-fangos-del-atlantico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 718",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-relajante-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 719",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-suenos-de-cereza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 720",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-de-25min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 721",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 722",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-15min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 723",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-sensaciones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 724",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-55min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 725",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-al-andalus/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 726",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 727",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 728",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-relax-del-rostro-cuello-y-escote/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 729",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 730",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-cerveza-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 731",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 732",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 733",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 734",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia-para-dos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 735",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-facial-kobido/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 736",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/la-utima-experiencia-de-belleza-esse/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 737",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-champi/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 738",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-abhyanga/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 739",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 740",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/limpieza-facial-profunda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 741",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 742",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-parejas-circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 743",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-paejas-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 744",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/circuito-ohtels/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 745",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-pareja-circuito-spa-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 746",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 747",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 748",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 749",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 750",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 751",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-2-pax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 752",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-1-pax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 753",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-hidratacion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 754",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/abama-total-wellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 755",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-bio-energetico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 756",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-presion-profunda-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 757",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-pureza-marina/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 758",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/pura-experiencia-detox/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 759",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/ritual-canario-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 760",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-sandara-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 761",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-balines/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 762",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/experiencia-ayurvedica/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 763",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-balines/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 764",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-alojado-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 765",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-externo-2-personas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 766",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-15-minutos-en-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 767",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-holistico-con-aceite-de-cerveza-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 768",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 769",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 770",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 771",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 772",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-15-minutos-en-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 773",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 774",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 775",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 776",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 777",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 778",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 779",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-2-adultos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 780",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 781",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 782",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-masaje-relajante-y-cava-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 783",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-y-masaje-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 784",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 785",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 786",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 787",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-3/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 788",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 789",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-cena-de-lunes-a-viernes-noches/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 790",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-120-min-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 791",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-cena-de-lunes-a-viernes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 792",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-envoltura-de-algas-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 793",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 794",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/jacuzzi-masaje-en-pareja-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 795",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/ritual-purificante-y-renovador-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 796",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-expres-con-acido-hialuronico-i-q10-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 797",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 798",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-gastro-fin-de-semana/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 799",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abyhanga/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 800",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-gastro-fines-de-semana-y-festivos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 801",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-en-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 802",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 803",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 804",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 805",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-almuerzo-de-lunes-a-viernes-mediodia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 806",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-lunes-a-viernes-festivos-no-incluidos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 807",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 808",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 809",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 810",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/drenaje-linfatico-de-50-circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 811",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 812",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 813",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/especial-pareja-circuito-spa-y-belleza-masaje-15min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 814",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/especial-parejas-circuito-spa-y-belleza-masaje-55min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 815",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/especial-pareja-circuito-spa-y-belleza-masaje-de-25min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 816",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/especial-pareja-circuito-ohtels-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 817",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 818",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 819",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/circuito-ohtels/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 820",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 821",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-holistico-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 822",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 823",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 824",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 825",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-de-cerveza-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 826",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 827",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/ritual-chocoterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 828",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/piscina-de-tratamientos-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 829",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-15-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 830",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 831",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-55-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 832",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 833",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 834",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/limpieza-facial-profunda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 835",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/piscina-interior-con-chorros-lumbares-y-cervicales/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 836",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 837",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 838",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 839",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 840",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 841",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 842",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 843",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 844",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 845",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 846",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 847",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 848",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 849",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 850",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 851",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-supreme-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 852",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 853",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 854",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 855",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-30min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 856",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 857",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/love-massage-50min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 858",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 859",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-algas-intensive/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 860",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-teide-magico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 861",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-secreto-de-la-india/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 862",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 863",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-descontracturante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 864",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-cava-y-chocolate/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 865",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-afrodita/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 866",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-personalizado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 867",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-ayurveda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 868",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 869",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-tratamiento-facial-basico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 870",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-reflexologia-podal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 871",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 872",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-el/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 873",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-ella/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 874",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/love-secret/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 875",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-happy-mum/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 876",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 877",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 878",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 879",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa-nino-5-15-anos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 880",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 881",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 882",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 883",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 884",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-baby-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 885",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 886",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/foot-reflexology-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 887",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/deep-tissue-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 888",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/oriental/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 889",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/antiestres-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 890",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 891",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/oriental-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 892",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/escapada-relax-en-pareja-cena-spa-y-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 893",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/senses-night/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 894",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/circuito-hydrowellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 895",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/mananas-deluxe/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 896",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/foot-reflexology/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 897",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/deep-tissue-40/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 898",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 899",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/aquae-private-experience-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 900",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/romantic-private-experience-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 901",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-private-experience-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 902",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-experience/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 903",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-general/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 904",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 905",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/facial-express/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 906",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-maderoterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 907",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-air-bio-spa-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 908",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 909",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-privado-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 910",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 911",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 912",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 913",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 914",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 915",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-oasis-bio-120-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 916",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-familiar-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 917",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 918",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 919",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-spa-privado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 920",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-familiar-privada-padres-e-hijos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 921",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 922",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 923",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa-luxury/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 924",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/ritual-de-chocoterapia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 925",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 926",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-15-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 927",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-55-min-servicio-de-te-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 928",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 929",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/depilacion-piernas-completas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 930",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-full-body-75/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 931",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/depilacion-de-torax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 932",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 933",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 934",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/cura-relax-antiestres-desfatigante-2-dias/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 935",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 936",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-de-tratamientos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 937",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-interior-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 938",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 939",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-holistico-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 940",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 941",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 942",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/manicura-senza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 943",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-pareja/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 944",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 945",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 946",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-reflexologia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 947",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-expres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 948",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-calmante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 949",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 950",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 951",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 952",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 953",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-prenatal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 954",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 955",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 956",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-con-circuito-termal-120min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 957",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/circuito-hydrowellness/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 958",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 959",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 960",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 961",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 962",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 963",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/after-work/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 964",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/est-facial-hidratante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 965",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-happy-mum/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 966",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 967",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/body-care-uli-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 968",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-baby-relax/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 969",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/love-secret/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 970",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 971",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 972",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-60-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 973",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 974",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 975",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-deep-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 976",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 977",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 978",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-oxigen/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 979",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-go-sun-passion-fruit/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 980",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-30/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 981",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-60/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 982",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 983",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-cromoterapia-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 984",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/deep-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 985",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-reiki-masaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 986",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/rituales-diosas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 987",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/antiestres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 988",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-massage/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 989",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/cocoon-therapy-envolturas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 990",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 991",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 992",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/serenity/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 993",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 994",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/essences-senses/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 995",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/booster-vitamin-c/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 996",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy-force/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 997",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-intense/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 998",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/excellence-marine-collagen-lift/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 999",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1000",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1001",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1002",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/peeling/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1003",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/resugence/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1004",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/day-pass-spa-masaje-25min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1005",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1006",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-ritual/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1007",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1008",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1009",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1010",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/exclusividad-circuito/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1011",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-marrakech/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1012",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-kenitra/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1013",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-asilah/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1014",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-essaouira/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1015",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-5-sesiones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1016",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-10-sesiones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1017",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-facial-de-cuello-cabeza-y-cara-mascarilla-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1018",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-20-sesiones/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1019",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/circuito-clasico/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1020",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/exfoliacion-en-seco/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1021",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1022",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1023",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-reflexologia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1024",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-express-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1025",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-calmante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1026",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1027",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1028",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1029",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1030",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-prenatal/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1031",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1032",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1033",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/experiencia-espalda-en-equilibrio/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1034",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-20-min-1/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1035",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-vitalidad-e-hidratacion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1036",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-esencia-de-nammu/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1037",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/faciales-personalizados/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1038",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-50-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1039",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1040",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/aromaterapia-con-vela/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1041",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-capricho-de-la-naturaleza/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1042",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/circuito-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1043",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-especial-pre-mama/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1044",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-anti-estres/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1045",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-hidroterapia-e-hidratacion/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1046",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-celta-de-piedras-calientes-y-frias-20-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1047",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-marino-remineralizante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1048",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-para-ninos/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1049",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1050",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1051",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1052",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1053",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1054",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1055",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/acceso-al-spa-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1056",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1057",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/circuito-relax-1-entrada/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1058",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1059",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oxigenacion-facial/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1060",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/bamboo-scrub/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1061",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/peeling-sales-envoltura-de-chocolate/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1062",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-lomi-lomi/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1063",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-de-espalda-y-cuello-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1064",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-masaje-y-buffet-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1065",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-y-buffet-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1066",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-relax-para-2/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1067",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/diamond-rose-experiencia/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1068",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oro-y-aceites-esenciales-relajantes-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1069",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/piedras-calientes/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1070",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/chocolate-relajante-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1071",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/ayurveda/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1072",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/facial-ritual-of-beauty-30-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1073",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/banera-hidromasaje/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1074",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/especial-pareja-circuito-spa-masaje-de-25-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1075",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/especial-pareja-circuito-spa-masaje-de-15-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1076",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/especial-pareja-circuito-spa-servicio-de-te-parejas/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1077",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/masaje-de-aromaterapia-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1078",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/tratamiento-hidratante-source-marine/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1079",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/masaje-esencia-natural-chocolate-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1080",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/circuito-spa-vila-roamana/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1081",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/masaje-exclusivity-55-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1082",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/masaje-exclusivity-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.name ?? "Service landing: 1083",
    path: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/masaje-esencia-natural-cerveza-25-min/",
    props: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.props ?? false,
    meta: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta || {},
    alias: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.alias || [],
    redirect: ServiceDetail_46pageFlGVQeEHLpvxNOQ65coweHvIHloRwFvTb7OhaRuiCDYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c43e14a6-6709-4885-b50a-39081b21b30a/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]